@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');

html,
body {
    margin: 0;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    background: #fff;
    border: 3px solid #000;
    border-radius: 70px;
    box-shadow: 0 2px 0 2px #000;
    position: absolute;
    top: 30px;
}

.header a {
    text-decoration: none;
}

.brand {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 15px 5px;
}

.brand-logo {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-decoration: none;
    color: #212529;
    font-weight: 700
}

.nav-link_ref {
    text-decoration: none;
    display: block;
    padding: 10px;
    color: #fff;
    margin-bottom: 5px;
}

.nav-link_ref:hover {
    text-decoration: none;
    opacity: 0.9;
}

.is-active {
    display: none;
}

.hero {
    margin: auto;
    background-color: #fff;
    min-height: 100vh;
    background-image: url('./img/bg-big.png');
    background-position: 50%;
    background-size: cover;
}

.caption {
    position: absolute;
    left: 50%;
    bottom: 60px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;
}

.caption-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.about {
    padding: 30px 50px 50px 40px;
}

.about-title {
    font-size: 36px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000;
    text-transform: uppercase;
}

.lead {
    font-size: 22px;
    line-height: 29px;
}

.text-center {
    text-align: center !important;
}

.roadmap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 50px;
    font-family: 'Source Sans Pro', arial, sans-serif;
    font-weight: 300;
    color: #333;
    box-sizing: border-box;
}

.roadmap-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}

.roadmap-title {
    color: #fff;
    font-size: 48px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000;
    text-transform: uppercase;
    margin: 0;
}

.roadmap * {
    box-sizing: border-box;
}

.timeline {
    width: 100%;
    max-width: 600px;
    background: #fff;
    padding: 100px 50px;
    position: relative;
    box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0px;
    left: calc(33% + 15px);
    bottom: 0px;
    width: 4px;
    background: #ddd;
}

.timeline:after {
    content: "";
    display: table;
    clear: both;
}

.entry {
    clear: both;
    text-align: left;
    position: relative;
}

.entry .title {
    margin-bottom: .5em;
    float: left;
    width: 33%;
    padding-right: 30px;
    text-align: right;
    position: relative;
}

.entry .title:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border: 4px solid salmon;
    background-color: #fff;
    border-radius: 100%;
    top: 15%;
    right: -6px;
    z-index: 99;
}

.entry .title h3 {
    margin: 0;
    font-size: 120%;
}

.entry .title p {
    margin: 0;
    font-size: 100%;
}

.entry .body {
    margin: 0 0 3em;
    float: right;
    width: 66%;
    padding-left: 30px;
}

.entry .body p {
    line-height: 1.4em;
}

.entry .body p:first-child {
    margin-top: 0;
    font-weight: 400;
}

.entry .body ul {
    color: #aaa;
    padding-left: 0;
    list-style-type: none;
}

.entry .body ul li:before {
    content: "–";
    margin-right: .5em;
}

.roadmap-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.roadmap-img {
    padding-bottom: 20px;
}

.sliderimg {
    width: 200px;
    height: 200px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

.footer p {
    font-size: 0.938rem;
}


.claim {
    padding-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}

.mintnum {
    width: 200px;
    height: 40px;
    color: #000;
    font-size: 28px;
    padding: 2px;
    margin: 4px;
    font-weight: bold;
}

.mintnum .mint-container {
    width: 100%;
    min-height: 80vh;
    margin-bottom: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mint-container p {
    font-size: 1.4rem;
    font-family: var(--font-secondary);
    margin-bottom: 2rem;
    text-align: center;
    text-transform: capitalize;
}

.mint-img {
    width: 200px;
    height: 200px;
}

.info-container {
    width: 80%;
    padding-left: 15%;
}

.info-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.my-items {
    padding-top: 5%;
    padding-bottom: 30px;
}

.items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, auto));
    justify-content: center;
    gap: 1.5rem;
}

.item-box {
    width: 300px;
    background: var(--bg-color);
    box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
    padding: 15px;
    border-radius: 0.5rem;
}

.item-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
}


.dashboard-section {
    width: 100%;
    color: #000;
    position: relative;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

.dashboard-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-content {
    display: "grid";
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-top: 4rem;
    padding-left: 20%;
    padding-bottom: 2rem;
}

.dashboard-container p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: capitalize;
}

.dashboard-row {
    display: flex;
    width: 700px;
}

.dashboard-left {
    width: 400px;
}

.dashboard-left label {
    padding-bottom: 10px;
}

.dashboard-button {
    padding-top: 34px;
    padding-left: 10px;
}

.dashboard-button-up {
    padding-top: 0px;
    padding-left: 10px;
}

/* FAQ */

.faq {
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-top: 40px;
}

.faqhead {
    font-size: 36px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000;
    text-transform: uppercase;
    margin-top: 12px;
    margin-left: 12px;
}

.faq-questions {
    width: 60%;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-left: 20%;
}

summary {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0);
    color: #000;
    padding: 1rem;
    margin-bottom: 0px;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
    padding-bottom: 10px;
}

.faq__content {
    font-family: 'Gilroy-Medium', sans-serif;
    margin-left: 15px;
    max-width: 400px;
}

details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
}

details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
}

details>summary::-webkit-details-marker {
    display: none;
}

details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}


@media (max-width: 767px) {

    .header {
        width: 80%;
        margin-left: 10%;
    }

    .nav-custom {
        background: rgba(75, 104, 184, 0.9);
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 20;
        top: 0;
        right: 0;
        overflow-x: hidden;
        transition: .5s;
        padding-top: 10px;
    }

    .open-menu {
        width: 250px;
    }

    .nav-burger {
        position: absolute;
        top: 10px;
        right: 25px;
    }

    .nav-cancel {
        position: absolute;
        top: 35px;
        right: 25px;
    }

    .nav-links_div {
        padding-top: 100px;
        padding-left: 10px;
    }

    .nav-link_ref {
        text-decoration: none;
        display: block;
        margin-bottom: 5px;
        padding: 5px;
        color: #fff;
    }

    .about-title {
        font-size: 24px;
    }

    .lead {
        font-size: 18px;
    }

    .entry .title h3 {
        margin: 0 50px 0px 0px;
    }

    .entry .title p {
        font-size: 12px;
    }

    .roadmap {
        display: flex;
        flex-direction: column-reverse;
    }

    .roadmap-container {
        width: 100%;
    }

    .roadmap-imgs {
        flex-direction: row;
        padding-left: 0px;
    }

    .roadmap-img {
        padding: 10px;
    }

    .sliderimg {
        width: 160px;
        height: 160px;
    }

    .footer {
        flex-direction: row;
        row-gap: 1rem;
        text-align: center;
    }

    .footer p {
        font-size: 14px;
    }

    .claim {
        padding-top: 20%;
        display: flex;
        flex-direction: column-reverse;
    }

    .mint-container {
        width: 100%;
        min-height: 70vh;
    }

    .item-box {
        width: 250px;
    }

    .dashboard {
        width: 100vw;
        height: auto;
    }

    .dashboard-section h1 {
        font-size: 32px;
    }

    .dashboard-container {
        width: 100%;
        min-height: 70vh;
    }

    .dashboard-content {
        padding-left: 0;

    }

    .dashboard-row {
        flex-direction: column;
        width: 300px;
    }

    .dashboard-left {
        width: 300px;
    }

    .dashboard-button {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dashboard-button-up {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .faq-questions {
        width: 100%;
        margin-left: 0%;
    }

}

@media (max-width: 570px) {
    .header {
        width: 85%;
    }

    .hero {
        background-image: url('./img/bg-small.png');
    }

    .sliderimg {
        width: 110px;
        height: 110px;
    }

    .info-container {
        width: 100%;
        padding-left: 0%;
    }

    .mint-container {
        width: 100%;
        min-height: 70vh;
        padding: 10px;
    }

    .faqhead {
        font-size: 24px;
    }
}

@media (max-width: 396px) {
    .header {
        width: 85%;
    }

    .sliderimg {
        width: 85px;
        height: 85px;
    }

    .about-title {
        margin-bottom: 0px;
    }

    .roadmap {
        padding: 0px;
    }

    .claim {
        padding-top: 30%;
    }

    .roadmap-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px;
    }

    .mint-container {
        width: 100%;
        min-height: 70vh;
        padding: 10px;
    }
}

@media (min-width: 768px) {
    .header {
        width: 80%;
        margin-left: 10%;
    }

    .nav-burger {
        display: none;
    }

    .nav-cancel img {
        display: none;
    }

    .nav-custom {
        display: block;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
    }

    .nav-link_ref {
        text-decoration: none;
        display: inline-block;
        padding: 15px 15px;
        color: #212529;
    }

    .dashboard {
        width: auto;
        height: auto;
    }

    .dashboard-container {
        width: 65%;
        min-height: 75vh;
    }

    .dashboard-content {
        padding-left: 240px;
    }

    .dashboard-left {
        width: 280px;
    }

    .dashboard-button {
        padding-top: 35px;
        padding-left: 10px;
    }

    .dashboard-button-up {
        padding-top: 0px;
        padding-left: 10px;
    }
}
